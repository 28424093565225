//巡检接口
import axios from "../axios";
import { baseURL } from "../web/index";
import { TableResponseType } from "components/table/interface";

interface inspectionListType {
  id: string;
  labelNum: number;
  name: string;
  labelType: string;
}

/**
 * 查询所有巡检点位信息
 */
export const getDataInspectionList = (param: any) =>
  axios.post<inspectionListType[]>(
    `${baseURL}/webapi/cruisePic/getListBy`,
    param
  );

/**
 * 查询所有巡检点位信息==分页
 */
export const getDataInspectionPage = (param: any) =>
  axios.post<TableResponseType<inspectionListType>>(
    `${baseURL}/webapi/cruisePic/getPageBy`,
    param
  );
