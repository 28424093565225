import React, { useEffect, useState } from 'react'
import NumberCard from './numberCard'
import { useInterval } from 'react-use';
import { getVehicleBigScreenNum, getBigScreenLabel } from 'server/web/largeScreen'
import RealTime from "./realTime"
import './index.less'

const InfoWrapper = (props: any) => {

  const [titleFlag, setTitleFlag] = useState(true)
  const [labelFlag, setLabelFlag] = useState(true)

  const [info, setInfo] = useState<{
    id?: string
    vehicleNum?: number
    mileageNum?: number
    companyNum?: number
    duration?: number
    onlineVehicleNum?: number
    onlineVehicleRatio?: string
    newVehicleNum?: number
    newVehicleRatio?: string
    breakdownVehicleNum?: number
    breakdownVehicleRatio?: string
    upgradeNum?: number
    upgradeRatio?: string
    examineNum?: number
    realOnlineVehicleNum?: string
    realOnlineVehicleRatio?: string
    newVehicleMonthNum?: string
    newVehicleMonthRatio?: string
    upgradeMonthNum?: string
    upgradeMonthRatio?: string
    examineMonthNum?: string
  }>({})
  const [labels, setLabels] = useState<{
    id: string
    labelTypeName: string
    labelTypeNum: number
  }[]>([])

  const getData = () => {

    getVehicleBigScreenNum().then(res => {
      if (res) {
        setInfo(res.data)
        setTitleFlag(!titleFlag)
      }
    })

    getBigScreenLabel().then(res => {
      if (res) {
        setLabels(res.data)
        setLabelFlag(!labelFlag)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  useInterval(() => {
    getData()
  }, 30000)

  return (
    <div className='tx-large'>
      <div>
        <div className='tx-large-left'>
          <NumberCard flag={labelFlag} alarmNum={1} title={'今日在线数'} value={info?.onlineVehicleNum}
            icon={'在线'} />
          <NumberCard flag={labelFlag} alarmNum={1} title={'今日在线率'} value={info?.onlineVehicleRatio}
            icon={'在线'} showPercent />
          <NumberCard flag={labelFlag} alarmNum={1} title={'今日新增数'} value={info?.newVehicleNum}
            icon={'新增'} />
          <NumberCard flag={labelFlag} alarmNum={1} title={'今日新增率'} value={info?.newVehicleRatio}
            icon={'新增'} showPercent />
          <NumberCard flag={labelFlag} alarmNum={1} title={'今日升级数'} value={info?.upgradeNum}
            icon={'APP升级'} />
          <NumberCard flag={labelFlag} alarmNum={1} title={'今日升级率'} value={info?.upgradeRatio}
            icon={'APP升级'} showPercent />
          {/* <NumberCard flag={labelFlag} alarmNum={1} title={'今日验收数'} value={info?.examineNum}
            icon={'已验收'} /> */}
        </div>
        <div className='tx-large-left' style={{ left: 180 }}>
          <NumberCard flag={labelFlag} alarmNum={1} title={'实时在线数'} value={info?.realOnlineVehicleNum} />
          <NumberCard flag={labelFlag} alarmNum={1} title={'实时在线率'} value={info?.realOnlineVehicleRatio} showPercent />
          <NumberCard flag={labelFlag} alarmNum={1} title={'当月新增数'} value={info?.newVehicleMonthNum} />
          <NumberCard flag={labelFlag} alarmNum={1} title={'当月新增率'} value={info?.newVehicleMonthRatio} showPercent />
          <NumberCard flag={labelFlag} alarmNum={1} title={'当月升级数'} value={info?.upgradeMonthNum} />
          <NumberCard flag={labelFlag} alarmNum={1} title={'当月升级率'} value={info?.upgradeMonthRatio} showPercent />
          {/* <NumberCard flag={labelFlag} alarmNum={1} title={'当月验收数'} value={info?.examineMonthNum} /> */}
        </div>
        <div className='tx-large-right'>
          <div className="large-right-top">
            <RealTime />
          </div>
          <div className="large-main-title">
            <div>
              <span className={titleFlag ? 'fade1' : 'fade2'}>{info.vehicleNum || 0}</span><br />
              车辆总数
            </div>
            <div>
              <span className={titleFlag ? 'fade1' : 'fade2'}>{info.companyNum || 0}</span><br />
              企业总数
            </div>
            <div>
              <span className={titleFlag ? 'fade1' : 'fade2'}>{info.mileageNum || 0}</span><br />
              今日总里程
            </div>
            <div>
              <span className={titleFlag ? 'fade1' : 'fade2'}>{info.duration || 0}</span><br />
              今日总时长
            </div>
          </div>
          <div className='large-right-bottom'>
            <div className='large-right-bottom-left'>
              {props.children}
            </div>
          </div>
        </div>
        <div className='tx-large-left' style={{ right: 0 }}>
          <NumberCard flag={labelFlag} alarmNum={1} title={'超速驾驶'} value={labels[0]?.labelTypeNum}
            icon={'超速'} />
          <NumberCard flag={labelFlag} alarmNum={1} title={'疲劳驾驶'} value={labels[1]?.labelTypeNum}
            icon={'疲劳驾驶'} />
          <NumberCard flag={labelFlag} alarmNum={1} title={'激进驾驶'} value={labels[2]?.labelTypeNum}
            icon={'行驶驾驶'} />
          <NumberCard flag={labelFlag} alarmNum={1} title={'分心驾驶'} value={labels[3]?.labelTypeNum}
            icon={'分心驾驶'} />
          <NumberCard flag={labelFlag} alarmNum={1} title={'警惕性差'} value={labels[4]?.labelTypeNum}
            icon={'提示警惕'} />
          <NumberCard flag={labelFlag} alarmNum={1} title={'不合规'} value={labels[5]?.labelTypeNum}
            icon={'安全合规'} />
        </div>
      </div>
    </div>
  )
}

export default InfoWrapper

