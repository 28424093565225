import React, {useEffect, useState} from 'react'
import {useInterval} from 'react-use'
import EchartsMapComponent from "./components/echartsMapComponent"
import {getAreaVehicle, getOnlineVehicleTop, getVehicleGps} from 'server/web/largeScreen'
import InfoWrapper from "./infoWrapper"
import './index.less'
import {getLocalJson} from "util/comm"
import {getCompany} from "server/web/companypra"
import mapper from 'util/provinceMapper'

interface PointInfo {
  id: string
  gpsDate: string
  address: string
  simNo: string
  driverName: string
  companyName: string
  vehicleNo: string
}

type Point = [number, number, string]

type AniPoint = [number, number, PointInfo]

interface ProvinceItemInterface {
  name: string
  value: {
    vehicleNum: number
    onlineRate: string
  }
  label?: any
}

function EchartsMap() {

  /**
   * geoOption是个trick。
   * 载入的时候把带有center和zoom的geoOption传给ECharts，实现初始化。
   * 在后面刷新的时候清掉geoOption，主要是为了不带center和zoom，不然地图缩放和中心点会重置成默认
   */
  const [geoOption, setGeoOption] = useState<any>({
    center: [104.114129, 35.850339],
    zoom: 1.6,
    map: 'china',
    roam: false,
    itemStyle: {
      color: 'transparent',
      borderColor: 'rgba(255,255,255,0.1)',
      borderWidth: 1
    }
  })

  const [data, setData] = useState<{
    gpsList: Point[],
    animationPointList: AniPoint[],
    provinceData: ProvinceItemInterface[]
  }>({
    gpsList: [],
    animationPointList: [],
    provinceData: []
  })

  const getData = () => {
    const promise1 = getVehicleGps()
    const promise2 = getOnlineVehicleTop()
    const promise3 = getAreaVehicle()

    Promise.all([promise1, promise2, promise3]).then(res => {
      let tempGpsList: Point[] = []
      let tempAnimationList: any[] = []
      let tempProvinceDataList: ProvinceItemInterface[] = []
      if (res[0] && res[0].data.length) {
        tempGpsList = res[0].data.map(i => [parseFloat(i.longitude), parseFloat(i.latitude), i.id])
      }

      if (res[1] && res[1].data.length) {
        tempAnimationList = res[1].data.map(i => ({
          name: '1',
          value: [i.longitude, i.latitude],
          data: {
            id: i.id,
            gpsDate: i.gpsDate,
            address: i.address,
            longitude: i.longitude,
            latitude: i.latitude,
            simNo: i.simNo,
            driverName: i.driverName,
            companyName: i.companyName,
            vehicleNo: i.vehicleNo
          },
          label: {
            show: false,
            // formatter: '{a|{a}}{abg|}\\n{hr|}\\n  {b|{b}：}{c}  {per|{d}%}',
            backgroundColor: 'rgb(12,12,90)',
            borderColor: 'rgb(20,20,180)',
            borderWidth: 1,
            borderRadius: 4,
            zlevel: 100,
            padding: 10,
            align: 'left',
            lineHeight: 18,
            textStyle: {
              color: '#fff'
            },
            formatter: (params: any) => {
              return [
                `车牌号:${params.data.data.vehicleNo || '-'}`,
                `司机名:${params.data.data.driverName || '-'}`,
                `公司:${params.data.data.companyName || '-'}`,
              ].join('\n')
            },
          }
        }))
      }

      if (res[2] && res[2].data.length) {
        tempProvinceDataList = res[2].data.map((i: any) => {
          return {
            // @ts-ignore
            name: mapper[i.areaName],
            value: {
              vehicleNum: i.vehicleNum || '-',
              onlineRate: i.onlineRate || '-'
            }
          }
        })
      }

      // let temp: Point[] = []
      // for (let i = 0; i < 65535; i++) {
      //   temp.push([Math.random() * 60 + 74, Math.random() * 31 + 21, 'temp'])
      // }

      setData({
        gpsList: tempGpsList,
        animationPointList: tempAnimationList,
        provinceData: tempProvinceDataList
      })
    })
  }

  useEffect(() => {
    const userInfo: any = getLocalJson('tx_userInfo')
    // 获取企业参数
    if (userInfo) {
      getCompany({companyId: userInfo.orgId}).then(result => {
        if (result && result.data) {
          const {initLat = '35.850339', initLng = '104.114129', initZoomLevel = '1.6'} = result.data
          setGeoOption({
            center: [initLng, initLat],
            zoom: initZoomLevel
          })
        }
      })
    }

    getData()
    setTimeout(() => {
      setGeoOption({})
    }, 15000)
  }, [])

  useInterval(() => {
    getData()
  }, 60000)

  // useInterval(() => {
  //   if (data.provinceData) {
  //     setTimeout(() => {
  //       data.provinceData.map((i,index) => {
  //         if (index===provinceIndex) {
  //           i.label={
  //             formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
  //             backgroundColor: '#F6F8FC',
  //             borderColor: '#8C8D8E',
  //             borderWidth: 1,
  //             borderRadius: 4,
  //
  //             rich: {
  //               a: {
  //                 color: '#6E7079',
  //                 lineHeight: 22,
  //                 align: 'center'
  //               },
  //               hr: {
  //                 borderColor: '#8C8D8E',
  //                 width: '100%',
  //                 borderWidth: 1,
  //                 height: 0
  //               },
  //               b: {
  //                 color: '#4C5058',
  //                 fontSize: 14,
  //                 fontWeight: 'bold',
  //                 lineHeight: 33
  //               },
  //               per: {
  //                 color: '#fff',
  //                 backgroundColor: '#4C5058',
  //                 padding: [3, 4],
  //                 borderRadius: 4
  //               }
  //             }
  //           }
  //         } else {
  //           delete i.label
  //         }
  //         provinceIndex++
  //       })
  //     })
  //   }
  // }, 5000)

  return (
    <InfoWrapper>
      <EchartsMapComponent
        tooltip={{
          backgroundColor: 'rgb(12,12,90)',
          borderColor: 'rgb(20,20,180)',
          textStyle: {
            color: '#fff'
          },
          formatter(params: any) {
            if (params.seriesType === 'map') {
              if (params?.data?.value) {
                return `
                <b>${params.data.name}</b></b><br/>
                车辆数:${params.data.value.vehicleNum}<br/>
                在线率:${params.data.value.onlineRate}
                `
              }
              return '暂无数据'
            } else {
              if (params.data.data) {
                return `
                车牌号:${params.data.data.vehicleNo || '-'}<br/>
                司机名:${params.data.data.driverName || '-'}<br/>
                公司:${params.data.data.companyName || '-'}<br/>
                `
              }
              return '暂无数据'
            }
          }
        }}
        geo={geoOption}
        series={[{
          type: 'scatterGL',
          coordinateSystem: 'geo',
          dimensions: ['value'],
          symbolSize: 2,
          blendMode: 'lighter',
          itemStyle: {
            color: '#bdffe9'
          },
          data: data.gpsList
        } as any, {
          type: 'effectScatter',
          showEffectOn: 'render',
          rippleEffect: {
            color: '#bdffe9',
            brushType: 'fill',
            period: 4,
            scale: '8',
          },
          coordinateSystem: 'geo',
          dimensions: ['value'],
          symbolSize: 4,
          zlevel: 100,
          itemStyle: {
            color: '#bdffe9'
          },
          animation: false,
          data: data.animationPointList
        } as any, {
          type: 'map',
          mapType: 'china',
          geoIndex: 0,
          coordinateSystem: 'geo',
          data: data.provinceData
        } as any]}
      />
    </InfoWrapper>
  )
}

export default EchartsMap

