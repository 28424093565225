import React, { memo, forwardRef, useEffect } from "react";
import { SelectCodeProps, State } from "./interface";
import { isEqual } from "lodash-es";
import { Select, Spin, Empty } from "antd";
import useReducer from "util/useReducer";
import { getBasicCodes } from "server/web/basicCode";

const SelectCode = (props: SelectCodeProps, ref: React.Ref<any>) => {
  const {
    code,
    allowClear = true,
    queryTime = "onFocus",
    basicCodesOption: propsBasicCodesOption,
    defaultEchoAfterQuery = false,
    onFocus,
    filterWithKeys,
    ...extra
  } = props;
  const initialState: State = {
    options: undefined,
    filterOptions: undefined,
    loading: false,
    basicCodesOption: undefined,
  };
  const [state, dispatch] = useReducer(initialState);
  const { options, loading, basicCodesOption, filterOptions } = state;

  useEffect(() => {
    if (queryTime === "useEffect" && (!options || options.length === 0)) {
      queryBasicCodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (filterWithKeys) {
      const keyList = filterWithKeys.split(",");
      dispatch({
        type: "filterOptions",
        data: {
          filterOptions: options?.filter((item) =>
            keyList.includes(item.value)
          ),
        },
      });
    } else if (filterWithKeys === "") {
      dispatch({
        type: "filterOptions",
        data: {
          filterOptions: [],
        },
      });
    }
  }, [filterWithKeys, options]);

  /** 处理请求后的默认回显 */
  useEffect(() => {
    if (defaultEchoAfterQuery || defaultEchoAfterQuery === 0) {
      let echoNum =
        typeof defaultEchoAfterQuery === "boolean" ? 0 : defaultEchoAfterQuery;
      if (filterOptions && filterOptions.length > echoNum) {
        props.onChange &&
          props.onChange(filterOptions[echoNum].value, filterOptions[echoNum]);
      } else if (options && options.length > echoNum) {
        props.onChange &&
          props.onChange(options[echoNum].value, options[echoNum]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultEchoAfterQuery, filterOptions, options]);

  useEffect(() => {
    if (basicCodesOption) {
      dispatch({
        type: "options",
        data: {
          options: basicCodesOption.map((item) => ({
            label: item.text,
            value: item.key,
          })),
        },
      });
    }
  }, [basicCodesOption]);

  useEffect(() => {
    if (propsBasicCodesOption) {
      dispatch({
        type: "basicCodesOption",
        data: { basicCodesOption: propsBasicCodesOption },
      });
    }
  }, [propsBasicCodesOption]);

  const queryBasicCodes = async () => {
    if (code) {
      dispatch({ type: "loading", data: { loading: true } });
      const res = await getBasicCodes({ types: [code] });
      dispatch({ type: "loading", data: { loading: false } });
      if (res) {
        dispatch({
          type: "basicCodesOption",
          data: { basicCodesOption: res.data[code] },
        });
      }
    }
  };

  return (
    <Select
      allowClear={allowClear && !defaultEchoAfterQuery}
      options={filterOptions || options}
      ref={ref}
      notFoundContent={
        <Spin size="small" spinning={loading}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Spin>
      }
      loading={loading}
      {...extra}
      onFocus={(e) => {
        onFocus && onFocus(e);
        if (queryTime === "onFocus" && (!options || options.length === 0)) {
          queryBasicCodes();
        }
      }}
    />
  );
};

export default memo(forwardRef(SelectCode), isEqual);
