import React, { FC, SetStateAction, Dispatch, useState, useEffect } from 'react'
import { Modal } from 'components'
import { Descriptions } from 'antd';
import { useIntl } from 'react-intl'
import { getUserDetail } from 'server/web/user'
import { webAPIResponse } from 'server/web/index.globals'
import { setLocal } from 'util/comm';
interface Props {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}
interface logVo {
  /** 登录时间 */
  time?: string
  /** 登录IP */
  requestIp?: string
  /** 访问次数 */
  times?: string
}
interface orgVo {
  /** 企业名称 */
  name?: string
  /** 上级企业名称 */
  parentName?: string
  /** 企业地址 */
  detailedAddress?: string
  /** 联系人 */
  principal?: string
  /** 联系人电话 */
  principalPhone?: string
  /** 续时币余额 */
  continueCurrencyNum?: string
  orgType?: string
}
const UserCenter: FC<Props> = (props) => {
  const { formatMessage: f } = useIntl();
  const { visible, setVisible } = props;
  const [loading, setLoading] = useState(false);
  const [logVo, setlogVo] = useState<logVo>();
  const [orgVo, setOrgVo] = useState<orgVo>();
  const [userInfo, setUserInfo] = useState<webAPIResponse.userCenter>();
  const handleCancel = () => {
    setVisible(false)
  }
  useEffect(() => {
    const queryDate = async () => {
      let response = await getUserDetail();
      if (response) {
        const data = response?.data;
        setUserInfo(data);
        if (response.data) {
          setLocal('orgType', data.orgVo)
          setlogVo(response.data.logVo);
          setOrgVo(response.data.orgVo);
        }
      }
    }
    queryDate();
  }, [])
  return (
    <Modal
      title={f({ id: 'tx000051', description: '用户中心' })}
      visible={visible}
      onOk={handleCancel}
      onCancel={handleCancel}
      confirmLoading={loading}
      width={780}
    >
      <Descriptions title="个人信息">
        <Descriptions.Item label="用户名称">{userInfo?.name ? userInfo.name : '--'}</Descriptions.Item>
        <Descriptions.Item label="登录账号">{userInfo?.userAccount ? userInfo.userAccount : '--'}</Descriptions.Item>
        <Descriptions.Item label="角色">{userInfo?.roles ? userInfo.roles : '--'}</Descriptions.Item>
        <Descriptions.Item label="登录时间">{logVo?.time ? logVo.time : '--'}</Descriptions.Item>
        <Descriptions.Item label="访问IP">{logVo?.requestIp ? logVo.requestIp : '--'}</Descriptions.Item>
        <Descriptions.Item label="访问次数">{logVo?.times ? logVo.times : '--'}</Descriptions.Item>
      </Descriptions>
      <Descriptions title="企业信息">
        <Descriptions.Item label="企业名称">{orgVo?.name ? orgVo.name : '--'}</Descriptions.Item>
        <Descriptions.Item label="上级企业">{orgVo?.parentName ? orgVo.parentName : '--'}</Descriptions.Item>
        <Descriptions.Item label="企业地址">{orgVo?.detailedAddress ? orgVo.detailedAddress : '--'}</Descriptions.Item>
        <Descriptions.Item label="联系人">{orgVo?.principal ? orgVo.principal : '--'}</Descriptions.Item>
        <Descriptions.Item label="联系电话">{orgVo?.principalPhone ? orgVo.principalPhone : '--'}</Descriptions.Item>
        <Descriptions.Item label="续时币">{orgVo?.continueCurrencyNum ? orgVo.continueCurrencyNum : '--'}</Descriptions.Item>
      </Descriptions>
    </Modal>
  )
}

export default UserCenter;