import React, { useEffect, useState } from "react";
import { DatePicker as AntdDatePicker } from "antd";
import { DatePickerProps as AntdDatePickerProps } from "antd/lib/date-picker";
import moment, { Moment } from "moment";
import { DatePickerProps } from "./interface";
import RangePicker from "./rangePicker";

const DatePicker = (props: DatePickerProps) => {
  const { value, onChange, style, ...extra } = props;
  const [dateValue, setDateValue] = useState<Moment>();
  const onDateChange: AntdDatePickerProps["onChange"] = (date, dateString) => {
    onChange && onChange(dateString, date);
  };

  useEffect(() => {
    if (value) {
      let { picker } = props;
      setDateValue(
        value
          ? picker === "time"
            ? moment(value, "HH:mm:ss")
            : moment(value)
          : undefined
      );
    }
  }, [value]);

  return (
    <AntdDatePicker
      {...(extra as any)}
      style={
        style ? { ...style, width: style.width || "100%" } : { width: "100%" }
      }
      value={dateValue || undefined}
      onChange={onDateChange}
    />
  );
};

DatePicker.RangePicker = RangePicker;

export default DatePicker;
