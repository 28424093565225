/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-06-09 14:52:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-24 16:33:03
 */
import { observable, action } from 'mobx';
import { arrayToTree } from 'util/comm';
import BaseMenuList from 'router/config';
import { MyRouteProps } from 'router';
import { bucAPIResponse } from 'server/buc/index.globals';
import { remove } from 'lodash-es';

interface MenuList extends bucAPIResponse.MenuList {
  button: Array<string>;
  path?: string;
  children?: Array<MenuList>;
}

class MenuStore {
  /** 菜单数据 */
  @observable menu: MyRouteProps[] = [];
  /** 是否隐藏菜单
   * @param {boolean} false 隐藏菜单，true 显示菜单
   * @default false
   */
  @observable status: boolean = false;
  /** 是否隐藏按钮
   * @param {boolean} true 隐藏按钮, false 显示按钮
   * @default true
   */
  @observable isHidden: boolean = true;

  /** 更新菜单 */
  @action
  updateMenu = (data: Array<bucAPIResponse.MenuList> | null) => {
    if (data) {
      const routeMenu = data.map((item) => {
        const route = BaseMenuList.find((r) => r.name === item.uiRoute);

        return {
          ...route,
          ...item,
        };
      });

      const menu = this.handleChildrenPath(arrayToTree(this.handleButton(routeMenu), 'id', 'parentId'));
      console.log(menu);
      this.menu = menu;
    }
  };

  /** 跳转到第一个页面 */
  @action
  jumpFirstPage() {
    let router = '/index/monitor';
    if (this.menu.length) {
      const menuItem = this.menu[0];
      router = menuItem.path!;
      if (menuItem.children && menuItem.children.length) {
        router = menuItem.children[0].path!;
      }
    }
    return router;
  }

  @action
  updateStatus = (status?: boolean) => {
    this.status = status !== undefined ? status : !this.status;
  };

  @action
  updateShowMenuIcon = () => {
    this.isHidden = false;
  };

  @action
  updateHiddenMenuIcon = () => {
    this.isHidden = true;
  };

  handleButton(data: Array<bucAPIResponse.MenuList>): Array<MenuList> {
    const buttonArr = data.filter((item) => item.rightsType === '2');
    const noButton = remove(data, (item) => item.rightsType === '1');

    return noButton.map((item) => {
      return {
        ...item,
        button: buttonArr.filter((b) => b.parentId === item.id).map((n) => n.uiRoute),
      };
    });
  }

  handleChildrenPath(data: any[], path: string = '/index'): MyRouteProps[] {
    return data.map((item) => {
      item.path = path + '/' + item.uiRoute;
      if (item.children) {
        item.children = this.handleChildrenPath(item.children as any, item.path);
        if (item.children && item.children.length) {
          item.redirect = this.findFirstChildPath(item.children);
        }
      }
      return item;
    });
  }

  findFirstChildPath(data: any[]): string {
    let path = '';
    if (data && data.length) {
      const fItem = data[0];
      if (fItem.children) {
        path = this.findFirstChildPath(fItem.children);
      } else {
        path = fItem.path;
      }
    }
    return path;
  }
}

const menuStore: MenuStore = new MenuStore();

export { MenuStore };
export default menuStore;
