import React, {useState, memo} from 'react'
import moment from 'moment';
import {useInterval} from 'react-use';

function RealTime() {

  const [time, setTime] = useState<Array<string>>([])

  useInterval(() => {
    const nowTime: moment.Moment = moment(new Date())
    handleTime(nowTime)
  }, 1000)

  const handleTime = (time: moment.Moment) => {
    const Y = (time.year() + '').split('') // 年
    const M = (time.month() + 1 > 9 ? `${time.month() + 1}` : `0${time.month() + 1}`).split('') // 月
    const D = (time.date() > 9 ? `${time.date()}` : `0${time.date()}`).split('') // 日
    const H = (time.hour() > 9 ? `${time.hour()}` : `0${time.hour()}`).split('') // 时
    const m = (time.minute() > 9 ? `${time.minute()}` : `0${time.minute()}`).split('') // 分
    const s = (time.second() > 9 ? `${time.second()}` : `0${time.second()}`).split('') // 秒

    setTime([...Y, '-', ...M, '-', ...D, ...H, ':', ...m, ':', ...s])
  }

  return (
    <div className='large-right-top-time'>
      {
        time.map((t: string, i: number) =>
          <div key={i} className='time-item'>{t}</div>
        )
      }
    </div>
  )
}

export default memo(RealTime)
