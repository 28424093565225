import React, { useRef, useImperativeHandle, useEffect, forwardRef, useState } from 'react'
import classnames from 'classnames'
import Flv from 'flv.js'
import { baseProps } from './interface'
import './index'

// const createObservableLoader: any = (seeHandler: any, config: any) => {
//     console.log(seeHandler, config);
// }

const Video = (props: baseProps, ref: any) => {
    const videoElement: any = useRef(null)
    const flvPlayer: any = useRef(null)

    const [url, setUrl] = useState('')
    const [currentTime, setCurrentTime] = useState(0)

    useEffect(() => {
        if (props.url) {
            initFlvPlayer(props.url)
        } else if (flvPlayer.current) {
            destroy()
        }
    }, [props.url])

    useEffect(() => {
        if (props.onPlayTimeChange) {
            props.onPlayTimeChange(currentTime)
        }
    }, [currentTime])

    useEffect(() => {
        voiceChange(props.voice)
    }, [props.voice])

    useImperativeHandle(ref, () => {
        return {
            play: play,
            pause: pause,
            clean: cleanChunnk,
            dom: videoElement.current
        }
    })

    /** 初始化flv */
    const initFlvPlayer = (selfurl: string) => {
        destroyEvent()
        videoElement.current.oncanplay = play
        videoElement.current.onended = ended
        videoElement.current.addEventListener('timeupdate', events)
        if (props.type === 'flv') {
            const _: any = createFlvPlayer()
            flvPlayer.current = _
        } else {
            let _urlarr = url ? url.split('t=') : ''
            let _selfurlarr = selfurl ? selfurl.split('t=') : ''
            if (_urlarr && _urlarr[0] === _selfurlarr[0]) {
                videoElement.current.currentTime = 0
                videoElement.current.play()
            } else {
                setUrl(selfurl || '')
            }
        }

    }

    const play = () => {
        if (props.onStart) {
            props.onStart()
        }
        palyEvent()
    }

    const ended = () => {
        if (props.onEnded) {
            props.onEnded()
        }
    }

    const pause = () => {

        pauseEvent()
    }

    const destroy = () => {
        // if (props.onDestroy) {
        //     props.onDestroy()
        // }
        destroyEvent()
    }

    const cleanChunnk = () => {
        if (flvPlayer.current) {
            let end = flvPlayer.current.buffered.end(0)
            flvPlayer.current.currentTime = end - 0.2
        }
    }

    const voiceChange = (data: any) => {
        if (flvPlayer.current) {
            flvPlayer.current.volume = Number(data) / 100
        }
    }

    const palyEvent = () => {
        if (videoElement.current) {
            videoElement.current.play()
        }
    }

    const pauseEvent = () => {
        if (videoElement.current) {
            videoElement.current.pause()
        }
    }

    const destroyEvent = () => {
        if (flvPlayer.current) {
            flvPlayer.current.pause();
            flvPlayer.current.unload();
            flvPlayer.current.detachMediaElement();
            flvPlayer.current.destroy();
            flvPlayer.current = null
            videoElement.current.oncanplay = null
            videoElement.current.removeEventListener('timeupdate', events)
        }
    }

    /** 创建flvPlayer对象 */
    const createFlvPlayer = (): Flv.Player | null => {

        const { url, type, hasAudio, hasVideo, isLive, isSplit } = props
        if (url) {
            const selfUrl = isSplit && type === 'flv' ? url.split('?')[0] : url
            const flvPlayer: Flv.Player = Flv.createPlayer({
                type: type || 'flv',
                url: selfUrl,
                hasAudio: hasAudio || false,
                hasVideo: hasVideo === false ? false : true,
                isLive: isLive === false ? false : true,
                withCredentials: false
            }, {
                /** 默认缓存180kb 开始播放视频 */
                stashInitialSize: 180,
                // customLoader: createObservableLoader
            });

            flvPlayer.attachMediaElement(videoElement.current)
            flvPlayer.load()
            if (props.onInstanceCreated) {
                props.onInstanceCreated(flvPlayer)
            }
            return flvPlayer
        }
        return null
    }

    // 视频播放监听事件
    const events = () => {
        if (videoElement.current) {
            // 获取整数
            setCurrentTime(~~videoElement.current.currentTime)
        }
    }

    return (
        <video
            muted
            ref={ videoElement }
            className={ (classnames('flv-video', props.className)) }
            src={ url }
        >
        </video>
    )
}

export default forwardRef(Video)
