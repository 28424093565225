import React, { useState, memo } from 'react'
import { Form, Row, Col, Empty, Spin } from 'antd'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { DrawerTabs as Tabs } from 'components'
import { FormInstance } from 'antd/lib/form'
import { isEqual } from 'lodash-es'
import { FormTabsProps, FormItemProps } from './interface'
import classnames from 'classnames'
import { handleLayout } from 'util/comm'
import './index.less'

const { TabPane } = Tabs
const { Item } = Form

const FormTabs = (props: FormTabsProps) => {
  const {
    row: propsRow = 2,
    form,
    initialValues,
    loading,
    formProps,
    tabsProps,
    config,
    className,
  } = props
  const [activeKey, setActiveKey] = useState<string>()

  const handleForm = (form?: FormInstance) => {
    if (form) {
      const validateFields = form.validateFields
      form.validateFields = nameList =>
        new Promise((resolve, reject) => {
          validateFields(nameList)
            .then(resolve)
            .catch((e: ValidateErrorEntity) => {
              const {
                errorFields: [{ name }],
              } = e
              const key = findTabKeyWithName(name.join())
              if (key) {
                setActiveKey(key)
              }
              reject(e)
            })
        })
    }
    return form
  }

  const [_form] = useState(() => handleForm(form))

  const createItem = (option: FormItemProps[], layout?: any) => {
    return (
      <Row gutter={24}>
        {option.map((item, index) => {
          const { row = 1, style, ...extra } = item
          return (
            <Col key={String(item.label) + index} span={24 * (row / propsRow)}>
              <Item
                {...(layout ? layout : handleLayout(propsRow / row))}
                style={{
                  ...style,
                  marginLeft: style?.marginLeft || propsRow / row === 1 ? -3 : undefined,
                }}
                {...extra}
              />
            </Col>
          )
        })}
      </Row>
    )
  }

  const arrayToString = (arr: any[] | string | number): string =>
    Array.isArray(arr) ? arr.join() : String(arr)

  const findTabKeyWithName = (str: string): string | void => {
    if (config) {
      for (let i = 0; i < config.length; i++) {
        const { key, form } = config[i]
        if (form) {
          const bool = form.some(item => {
            const { name } = item
            return name ? str === arrayToString(name) : false
          })
          if (bool) {
            return key
          }
        }
      }
    }
  }

  return (
    <Spin spinning={loading === true}>
      <Form
        {...handleLayout(propsRow)}
        {...formProps}
        form={_form}
        initialValues={initialValues}
        className={classnames('tx-form-tabs', className)}
      >
        <Tabs {...tabsProps} activeKey={activeKey} onChange={setActiveKey}>
          {config?.map(item => {
            const { form, title, key, wrapperCol, labelCol, ...extra } = item
            return (
              <TabPane tab={title} key={key} {...extra}>
                {form ? (
                  createItem(form, wrapperCol || labelCol ? { wrapperCol, labelCol } : undefined)
                ) : (
                  <Empty style={{ marginTop: 100 }} />
                )}
              </TabPane>
            )
          })}
        </Tabs>
      </Form>
    </Spin>
  )
}

export default memo(FormTabs, isEqual)
