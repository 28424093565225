import React from 'react'
import BaseMap from './index';
import { MapProps } from './interface'
import { observer } from 'mobx-react'

function Map(props: MapProps) {
  const { center, zoom, type, ...extra } = props

  return (<BaseMap
    center={ center}
    zoom={ zoom }
    type={ type }
    { ...extra }
  />
  )
}

export default observer(Map)
