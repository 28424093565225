/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-10-14 14:07:59
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-26 17:53:56
 */
import { observable, action } from "mobx";
import { webAPIRequest, webAPIResponse } from "server/web/index.globals";

import { debounce } from "lodash-es";

import { PathToolType } from "components/ol/pathSimplifier";
import { positionStory } from 'store';


interface PositionTableData extends webAPIResponse.TrackList {
  idx: number;
}

class Trajectory {
  /** 轨迹回放-位置数据-查询条件 */
  @observable positionTableParam: webAPIRequest.TrackList = {
    isAbnormal: 0,
    minSpeed: "0",
    plateNo: "",
    current: 1,
    size: 50,
    begTime: "",
    endTime: "",
  };
  /** 轨迹回放-位置数据 */
  @observable positionTableData: PositionTableData[] = [];
  /** 总页数 */
  @observable positionTableTotal: number = 0;
  /** 轨迹回放-轨迹操作code */
  @observable toolCode: string = PathToolType.none;
  /** 轨迹回放-轨迹idx */
  @observable idx: number = 0;
  /** 轨迹回放-跳转轨迹idx */
  @observable jumpIdx: number = 0;
  @observable loading: boolean = false;
  /** 车辆详情 */
  @observable vehicleInfo: Partial<webAPIResponse.vehiclesInfo> = {};
  /** 车辆详情 - 用于infoWindow */
  @observable infoWindow: webAPIResponse.vehiclesInfo | null = null;
  /** infoWindow Type
   * @param 1 位置infowindow
   * @param 2 报警infowindow
   */
  @observable infoWindowType: number = 1;
  /** infoWindowVisible */
  @observable infoWindowVisible: boolean = false;
  /** 报警坐标 */
  @observable policeMarkers: Array<webAPIResponse.monitorTrajectoryAlarmPage> =
    [];
  /** 报警列表参数 */
  @observable policeParams: {} = {};
  /** 绘制轨迹回放loading */
  @observable drawLoading: boolean = false;

  /** infoWindow */
  updateInfoWindow(data: any) {
    this.infoWindow = data;
  }

  /** 清除轨迹 */
  @action
  cleanPath() {
    this.positionTableTotal = 0;
    this.positionTableData = [];
  }

  /** 更新请求参数 */
  @action
  updatePoliceParmas(data: {}) {
    this.policeParams = data;
  }

  /** 更新报警点位 */
  @action
  updateMarker(data: Array<webAPIResponse.monitorTrajectoryAlarmPage>) {
    this.policeMarkers = data;
  }

  /** 更新infoWindowType */
  updateInfoWindowType(type: number) {
    this.infoWindowType = type;
  }

  /** 更新车辆详情 */
  @action
  updateVehicleInfo(data: webAPIResponse.vehiclesInfo) {
    this.vehicleInfo = data;
  }

  /** 更新drawLoading */
  updateDrawLoading(data: boolean) {
    this.drawLoading = data;
  }
}

const trajectoryStory: Trajectory = new Trajectory();

export { Trajectory };
export default trajectoryStory;
