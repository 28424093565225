import React, { useEffect, useState, useMemo } from 'react'
import { Layout, Menu, Dropdown, Badge } from 'antd'
import { DownOutlined, MenuFoldOutlined, BellOutlined } from '@ant-design/icons'
import { useHistory, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { menuStore, platformParamsStore, userStore, faultListStore } from 'store'
import { useObserver } from 'mobx-react'
import classnames from 'classnames'
import ChangePassword from './changePassword'
import UserCenter from './userCenter'
import './index.less'
import { changeProjectName, createDownLoadUrl, getLocalJson } from 'util/comm'
import FaultList from "./faultList"
import { useInterval } from "react-use"

const { Header } = Layout
const { Item } = Menu

const hiddenMenuRouter = ['home', 'monitor']

function Index() {
  let location = useLocation()
  const [visible, setVisible] = useState(false)
  const [userCenter, setUserCenter] = useState(false);
  const [faultListVisible, setFaultListVisible] = useState(false)
  const [selectKeys, setSelectKeys] = useState<string[]>([''])
  const { formatMessage: f } = useIntl()
  const history = useHistory()
  const orgTypeInfo: any = getLocalJson('orgType')
  useEffect(() => {
    const pathname: string = location.pathname
    const pathNames = pathname.split('/')

    // 控制左侧收起按钮
    if (hiddenMenuRouter.some((name: string) => name === pathname)) {
      menuStore.updateHiddenMenuIcon()
    } else {
      menuStore.updateShowMenuIcon()
    }
    if (pathNames) {
      setSelectKeys([pathNames[2]])
    }
  }, [location])

  useEffect(() => {
    faultListStore.updateCount()
  }, [])

  useInterval(() => {
    faultListStore.updateCount()
  }, 60000)

  const menu = useMemo(
    () => (
      <Menu>
        <Menu.Item>
          <span onClick={() => setUserCenter(true)}>
            {f({ id: 'tx000051', description: '用户中心' })}
          </span>
        </Menu.Item>
        <Menu.Item>
          <span onClick={() => setVisible(true)}>
            {f({ id: 'tx000027', description: '修改密码' })}
          </span>
        </Menu.Item>
        <Menu.Item>
          <span onClick={signOut}>{f({ id: 'tx000028', description: '退出登录' })}</span>
        </Menu.Item>
      </Menu>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  function signOut() {
    window.location.href = '/login'
  }

  return useObserver(() => (
    <Header className="tx-header">
      <div className="logo">
        <img
          src={
            platformParamsStore.params.systemLogo ?
              createDownLoadUrl(platformParamsStore.params.systemLogo) : "/images/header/logo.png"
          }
          alt="" />
        <span className="logo-title">
          {
            changeProjectName(platformParamsStore.params.systemTitle).logoName
          }
        </span>
        <div
          className={classnames('menu-click-icon', {
            active: menuStore.status,
            hidden: menuStore.isHidden,
          })}
          onClick={() => {
            menuStore.updateStatus()
          }}
        >
          <MenuFoldOutlined />
        </div>
      </div>
      <div className="header-right">
        {menuStore.menu && menuStore.menu.length > 0 ? (
          <Menu theme="dark" mode="horizontal" selectedKeys={selectKeys}>
            {menuStore.menu.map((menu: any) => {
              return (
                <Item key={menu.name}>
                  {menu.isTabs ? (
                    <a className="tx-header-menu-link" href={menu.redirect} target="_blank">
                      <div className="header-item">
                        <img src={`/images/header/${menu.icon}.png`} alt="" />
                        {menu.intlTitle ? f({ id: menu.intlTitle }) : menu.rightsName}
                      </div>
                    </a>
                  ) : (
                    <span className="tx-header-menu-link" onClick={() => {

                      if (!window.location.href.includes(menu.path)) {
                        history.push(menu.path)
                      }
                    }}>
                      <div className="header-item">
                        <img src={`/images/header/${menu.icon}.png`} alt="" />
                        {menu.intlTitle ? f({ id: menu.intlTitle }) : menu.rightsName}
                      </div>
                    </span>
                  )}
                </Item>
              )
            })}
          </Menu>
        ) : null}

        <div className="user">
          <span>
            {
              orgTypeInfo.orgType == 'terminalCompany' ?
                <Badge count={faultListStore.count} size="small" offset={[-10, 0]}>
                  <BellOutlined className="header-setting" onClick={() => orgTypeInfo.orgType == 'terminalCompany' ? setFaultListVisible(true):setFaultListVisible(false)} />
                </Badge>
                : null
            }
          </span>
          {/*<SettingOutlined className="header-setting" onClick={() => setSettingVisible(true)}/>*/}

          <Dropdown overlay={menu}>
            <div className="header-user-menu">
              <span className="header-username ellipsis">
                {userStore.userInfo?.name || 'username'}
              </span>
              <DownOutlined />
            </div>
          </Dropdown>

          <FaultList visible={faultListVisible} setVisible={setFaultListVisible} />
          <UserCenter visible={userCenter} setVisible={setUserCenter} />
          <ChangePassword visible={visible} setVisible={setVisible} />
        </div>
      </div>
    </Header>
  ))
}

export default Index
