
export const MapDarkStyle: Array<any> =
[{
  "featureType": "land",
  "elementType": "geometry",
  "stylers": {
    "color": "#fcf9f2ff"
  }
}, {
  "featureType": "water",
  "elementType": "geometry",
  "stylers": {
    // "color": "#c9e59eff"
    "color": "#c4d7f5ff"
    
  }
}, {
  'featureType': 'boundary',
  'elementType': 'all',
  'stylers': {
    'color': '#2592fe'
  }
}, {
  "featureType": "highway",
  "elementType": "geometry.fill",
  "stylers": {
    "color": "#b5caa0ff"
  }
}, {
  "featureType": "highway",
  "elementType": "geometry.stroke",
  "stylers": {
    "color": "#94ad79ff"
  }
}, {
  "featureType": "nationalway",
  "elementType": "geometry.fill",
  "stylers": {
    "color": "#b5caa0ff"
  }
}, {
  "featureType": "arterial",
  "elementType": "geometry.fill",
  "stylers": {
    "color": "#d4e2c6ff"
  }
}, {
  "featureType": "cityhighway",
  "elementType": "geometry.fill",
  "stylers": {
    "color": "#d4e2c6ff"
  }
}, {
  "featureType": "provincialway",
  "elementType": "geometry.fill",
  "stylers": {
    "color": "#d4e2c6ff"
  }
}, {
  "featureType": "provincialway",
  "elementType": "geometry.stroke",
  "stylers": {
    "color": "#b5caa0ff"
  }
}, {
  "featureType": "tertiaryway",
  "elementType": "geometry.fill",
  "stylers": {
    "color": "#ffffffff"
  }
}, {
  "featureType": "tertiaryway",
  "elementType": "geometry.stroke",
  "stylers": {
    "color": "#b5caa0ff"
  }
}, {
  "featureType": "fourlevelway",
  "elementType": "geometry.fill",
  "stylers": {
    "color": "#ffffffff"
  }
}, {
  "featureType": "fourlevelway",
  "elementType": "geometry.stroke",
  "stylers": {
    "color": "#b5caa0ff"
  }
}, {
  "featureType": "subway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off"
  }
}, {
  "featureType": "railway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off"
  }
}, {
  "featureType": "highwaysign",
  "elementType": "labels",
  "stylers": {
    "visibility": "off"
  }
}, {
  "featureType": "highwaysign",
  "elementType": "labels.icon",
  "stylers": {
    "visibility": "off"
  }
}, {
  "featureType": "nationalwaysign",
  "elementType": "labels",
  "stylers": {
    "visibility": "off"
  }
}, {
  "featureType": "nationalwaysign",
  "elementType": "labels.icon",
  "stylers": {
    "visibility": "off"
  }
}, {
  "featureType": "provincialwaysign",
  "elementType": "labels",
  "stylers": {
    "visibility": "off"
  }
}, {
  "featureType": "provincialwaysign",
  "elementType": "labels.icon",
  "stylers": {
    "visibility": "off"
  }
}, {
  "featureType": "tertiarywaysign",
  "elementType": "labels",
  "stylers": {
    "visibility": "off"
  }
}, {
  "featureType": "tertiarywaysign",
  "elementType": "labels.icon",
  "stylers": {
    "visibility": "off"
  }
}, {
  "featureType": "subwaylabel",
  "elementType": "labels",
  "stylers": {
    "visibility": "off"
  }
}, {
  "featureType": "subwaylabel",
  "elementType": "labels.icon",
  "stylers": {
    "visibility": "off"
  }
}, {
  "featureType": "nationalway",
  "elementType": "geometry.stroke",
  "stylers": {
    "color": "#94ad79ff"
  }
}, {
  "featureType": "cityhighway",
  "elementType": "geometry.stroke",
  "stylers": {
    "color": "#b5caa0ff"
  }
}, {
  "featureType": "arterial",
  "elementType": "geometry.stroke",
  "stylers": {
    "color": "#b5caa0ff"
  }
}, {
  "featureType": "highway",
  "stylers": {
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "6"
  }
}, {
  "featureType": "highway",
  "stylers": {
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "7"
  }
}, {
  "featureType": "highway",
  "stylers": {
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "8"
  }
}, {
  "featureType": "highway",
  "stylers": {
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "9"
  }
}, {
  "featureType": "highway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "6"
  }
}, {
  "featureType": "highway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "7"
  }
}, {
  "featureType": "highway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "8"
  }
}, {
  "featureType": "highway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "9"
  }
}, {
  "featureType": "highway",
  "elementType": "labels",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "6"
  }
}, {
  "featureType": "highway",
  "elementType": "labels",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "7"
  }
}
, {
  "featureType": "highway",
  "elementType": "labels",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "8"
  }
}, {
  "featureType": "highway",
  "elementType": "labels",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "9"
  }
}, {
  "featureType": "nationalway",
  "stylers": {
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "6"
  }
}, 
{
  "featureType": "nationalway",
  "stylers": {
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "7"
  }
}, {
  "featureType": "nationalway",
  "stylers": {
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "8"
  }
}, {
  "featureType": "nationalway",
  "stylers": {
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "9"
  }
}, {
  "featureType": "nationalway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "6"
  }
}, {
  "featureType": "nationalway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "7"
  }
}, {
  "featureType": "nationalway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "8"
  }
}, {
  "featureType": "nationalway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "9"
  }
}, {
  "featureType": "nationalway",
  "elementType": "labels",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "6"
  }
}, {
  "featureType": "nationalway",
  "elementType": "labels",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "7"
  }
}, {
  "featureType": "nationalway",
  "elementType": "labels",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "8"
  }
}, {
  "featureType": "nationalway",
  "elementType": "labels",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "9"
  }
}, {
  "featureType": "provincialway",
  "stylers": {
    "curZoomRegionId": "0",
    "curZoomRegion": "8,9",
    "level": "8"
  }
}, {
  "featureType": "provincialway",
  "stylers": {
    "curZoomRegionId": "0",
    "curZoomRegion": "8,9",
    "level": "9"
  }
}, {
  "featureType": "provincialway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "8,9",
    "level": "8"
  }
}, {
  "featureType": "provincialway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "8,9",
    "level": "9"
  }
}, {
  "featureType": "provincialway",
  "elementType": "labels",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "8,9",
    "level": "8"
  }
}, {
  "featureType": "provincialway",
  "elementType": "labels",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "8,9",
    "level": "9"
  }
}, {
  "featureType": "cityhighway",
  "stylers": {
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "6"
  }
}, {
  "featureType": "cityhighway",
  "stylers": {
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "7"
  }
}, {
  "featureType": "cityhighway",
  "stylers": {
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "8"
  }
}, {
  "featureType": "cityhighway",
  "stylers": {
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "9"
  }
}, {
  "featureType": "cityhighway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "6"
  }
}, {
  "featureType": "cityhighway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "7"
  }
}, {
  "featureType": "cityhighway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "8"
  }
}, {
  "featureType": "cityhighway",
  "elementType": "geometry",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "9"
  }
}, {
  "featureType": "cityhighway",
  "elementType": "labels",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "6"
  }
}, {
  "featureType": "cityhighway",
  "elementType": "labels",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "7"
  }
}, {
  "featureType": "cityhighway",
  "elementType": "labels",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "8"
  }
}, {
  "featureType": "cityhighway",
  "elementType": "labels",
  "stylers": {
    "visibility": "off",
    "curZoomRegionId": "0",
    "curZoomRegion": "6,9",
    "level": "9"
  }
}, {
  "featureType": "entertainment",
  "elementType": "geometry",
  "stylers": {
    "color": "#e4f0d7ff"
  }
}, {
  "featureType": "manmade",
  "elementType": "geometry",
  "stylers": {
    "color": "#effcf0ff"
  }
}, {
  "featureType": "education",
  "elementType": "geometry",
  "stylers": {
    "color": "#e3f7e4ff"
  }
}, {
  "featureType": "building",
  "elementType": "geometry.stroke",
  "stylers": {
    "color": "#a1cfa4ff"
  }
}, {
  "featureType": "poilabel",
  "elementType": "labels",
  "stylers": {
    "visibility": "off"
  }
}, {
  "featureType": "poilabel",
  "elementType": "labels.icon",
  "stylers": {
    "visibility": "off"
  }
}, {
  "featureType": "education",
  "elementType": "labels.text.fill",
  "stylers": {
    "color": "#7a7a7aff"
  }
}, {
  "featureType": "education",
  "elementType": "labels.text.stroke",
  "stylers": {
    "color": "#ffffffff"
  }
}, {
  "featureType": "education",
  "elementType": "labels.text",
  "stylers": {
    "fontsize": 26
  }
}, {
  "featureType": "manmade",
  "elementType": "labels.text.fill",
  "stylers": {
    "color": "#afafafff"
  }
}, {
  "featureType": "manmade",
  "elementType": "labels.text",
  "stylers": {
    "fontsize": 26
  }
}, {
  "featureType": "scenicspotslabel",
  "elementType": "labels.text.fill",
  "stylers": {
    "color": "#376b6dff"
  }
}, {
  "featureType": "scenicspots",
  "elementType": "labels",
  "stylers": {
    "visibility": "off"
  }
}, {
  "featureType": "scenicspotslabel",
  "elementType": "labels",
  "stylers": {
    "visibility": "on"
  }
}, {
  "featureType": "scenicspotslabel",
  "elementType": "labels.text.stroke",
  "stylers": {
    "color": "#ffffffff",
    "weight": 4
  }
}, {
  "featureType": "country",
  "elementType": "labels.text.fill",
  "stylers": {
    "color": "#376b6dff"
  }
}, {
  "featureType": "country",
  "elementType": "labels.text.stroke",
  "stylers": {
    "color": "#ffffffff",
    "weight": 3
  }
}, {
  "featureType": "water",
  "elementType": "labels.text.fill",
  "stylers": {
    "color": "#ffffffff"
  }
}, {
  "featureType": "water",
  "elementType": "labels.text.stroke",
  "stylers": {
    "color": "#ffffff00"
  }
}, {
  "featureType": "water",
  "elementType": "labels.text",
  "stylers": {
    "fontsize": 24
  }
}]
// [{
//   'featureType': 'water',
//   'elementType': 'all',
//   'stylers': {
//     'color': '#ffffff'
//   }
// }, {
//   'featureType': 'land',
//   'elementType': 'all',
//   'stylers': {
//     'color': '#e6f3ff'
//   }
// }, {
//   'featureType': 'railway',
//   'elementType': 'all',
//   'stylers': {
//     'visibility': 'off'
//   }
// }, {
//   'featureType': 'highway',
//   'elementType': 'all',
//   'stylers': {
//     'color': '#fdfdfd'
//   }
// }, {
//   'featureType': 'highway',
//   'elementType': 'labels',
//   'stylers': {
//     'visibility': 'off'
//   }
// }, {
//   'featureType': 'arterial',
//   'elementType': 'geometry',
//   'stylers': {
//     'color': '#fefefe'
//   }
// }, {
//   'featureType': 'arterial',
//   'elementType': 'geometry.fill',
//   'stylers': {
//     'color': '#fefefe'
//   }
// }, {
//   'featureType': 'poi',
//   'elementType': 'all',
//   'stylers': {
//     'visibility': 'off'
//   }
// }, {
//   'featureType': 'green',
//   'elementType': 'all',
//   'stylers': {
//     'visibility': 'off'
//   }
// }, {
//   'featureType': 'subway',
//   'elementType': 'all',
//   'stylers': {
//     'visibility': 'off'
//   }
// }, {
//   'featureType': 'manmade',
//   'elementType': 'all',
//   'stylers': {
//     'color': '#d1d1d1'
//   }
// }, {
//   'featureType': 'local',
//   'elementType': 'all',
//   'stylers': {
//     'color': '#d1d1d1'
//   }
// }, {
//   'featureType': 'arterial',
//   'elementType': 'labels',
//   'stylers': {
//     'visibility': 'off'
//   }
// }, {
//   'featureType': 'boundary',
//   'elementType': 'all',
//   'stylers': {
//     'color': '#2592fe'
//   }
// }, {
//   'featureType': 'building',
//   'elementType': 'all',
//   'stylers': {
//     'color': '#d1d1d1'
//   }
// }, {
//   'featureType': 'label',
//   'elementType': 'labels.text.fill',
//   'stylers': {
//     'color': '#999999'
//   }
// }]
// [
//   {
//     'featureType': 'water',
//     'elementType': 'all',
//     'stylers': {
//       'color': '#ffffff'
//     }
//   },
//   {
//     'featureType': 'land',
//     'elementType': 'geometry',
//     'stylers': {
//       'color': '#e6f3ff'
//     }
//   },
//   {
//     'featureType': 'highway',
//     'elementType': 'all',
//     'stylers': {
//       'visibility': 'off',
//       "color":'#46a8ff'
//     }
//   },
//   {
//     'featureType': 'arterial',
//     'elementType': 'geometry.fill',
//     'stylers': {
//       // 'color': '#000000'
//       'color': '#9cd4ff'

//     }
//   },
//   {
//     'featureType': 'arterial',
//     'elementType': 'geometry.stroke',
//     'stylers': {
//       // 'color': '#0b3d51'
//       'color': '#383838'
//     }
//   },
//   {
//     'featureType': 'local',
//     'elementType': 'geometry',
//     'stylers': {
//       'color': '#000000'
//     }
//   },
//   {
//     'featureType': 'railway',
//     'elementType': 'geometry.fill',
//     'stylers': {
//       'color': '#000000'
//     }
//   },
//   {
//     'featureType': 'railway',
//     'elementType': 'geometry.stroke',
//     'stylers': {
//       'color': '#08304b'
//     }
//   },
//   {
//     'featureType': 'subway',
//     'elementType': 'geometry',
//     'stylers': {
//       'lightness': -70
//     }
//   },
//   {
//     'featureType': 'building',
//     'elementType': 'geometry.fill',
//     'stylers': {
//       'color': '#000000'
//     }
//   },
//   {
//     'featureType': 'all',
//     'elementType': 'labels.text.fill',
//     'stylers': {
//       'color': '#857f7f'
//     }
//   },
//   {
//     'featureType': 'all',
//     'elementType': 'labels.text.stroke',
//     'stylers': {
//       'color': '#000000'
//     }
//   },
//   {
//     'featureType': 'building',
//     'elementType': 'geometry',
//     'stylers': {
//       'color': '#022338'
//     }
//   },
//   {
//     'featureType': 'green',
//     'elementType': 'geometry',
//     'stylers': {
//       'color': '#98cefc'
//     }
//   },
//   {
//     'featureType': 'boundary',
//     'elementType': 'all',
//     'stylers': {
//       'color': '#2592fe'
//     }
//   },
//   {
//     'featureType': 'manmade',
//     'elementType': 'all',
//     'stylers': {
//       'color': '#022338'
//     }
//   },
//   {
//     'featureType': 'city',
//     'elementType': 'labels.text.stroke',
//     'stylers': {
//       'color': '#000000',
//     }
//   }
// ]