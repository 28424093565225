/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-10-14 18:31:11
 * @LastEditors: your Name
 * @LastEditTime: 2024-10-25 17:25:47
 */
import api from '.';
import axios from '../axios';
import { TableResponseType } from 'components/table/interface';
import { bucAPIRequest, bucAPIResponse, Id, Ids } from './index.globals';

const MODULE = '/user';

export interface ChangePasswordType {
  /** 旧密码 */
  oldPassword: string;
  /** 密码 */
  password: string;
  /** 重复密码 */
  verifyPassword: string;
}

interface FindAuthUsers {
  /** 企业id */
  companyId?: string;
  /** id */
  id?: string;
  /** 一页显示条数 */
  limit?: string;
  /** 用户的授权组织 */
  orgIds?: string;
  /** 当前页数 */
  page?: string;
  /** 是否开启分页 */
  queryByPage?: string;
  /** 需要导出的列标题 */
  titleStr?: string;
  /** 用户名模糊 */
  userAccountLike?: string;
}

interface RelieveAuth {
  /** 授权用户id */
  authUserIds: string[];
  /** 授权组织id */
  authOrgId: string;
}

export interface SetPasswordType {
  /** 旧密码 */
  oldPassword: string;
  /** 密码 */
  password: string;
  /** 重复密码 */
  userid: string;
}

/** 分页查询组织信息 */
export const queryUserList = (payload: bucAPIRequest.UserList) => axios.post<TableResponseType<bucAPIResponse.UserList>>(`${api}${MODULE}/list`, payload);

/** 通过ID得到用户信息 */
export const queryUserInfo = (payload: Id) => axios.post<bucAPIResponse.UserList>(`${api}${MODULE}/detail`, payload);

/** 新增用户 */
export const addUser = (payload: bucAPIRequest.AddUser) => axios.post<boolean>(`${api}${MODULE}/addUser`, payload);

/** 修改用户信息 */
export const updateUser = (payload: bucAPIRequest.UpdateUser) => axios.post<boolean>(`${api}${MODULE}/update`, payload);
/** 根据ID解锁用户 */
export const unLockUser = (payload: Id) => axios.post(`${api}${MODULE}/unlock`, payload);
/** 根据ID删除用户信息 */
export const deleteUser = (payload: Id) => axios.post<boolean>(`${api}${MODULE}/delete`, payload);

/** 根据ID修改用户状态为停用 */
export const userModifyDisable = (payload: Id) => axios.post<boolean>(`${api}${MODULE}/modify/state/disable`, payload);

/** 根据ID修改用户状态为正常 */
export const userModifyNormal = (payload: Id) => axios.post<boolean>(`${api}${MODULE}/modify/state/normal`, payload);

/** 批量停用 */
export const userModifyDisableBatch = (payload: Ids) => axios.post<boolean>(`${api}${MODULE}/modify/state/disable/batch`, payload);

/** 批量启用 */
export const userModifyNormalBatch = (payload: Ids) => axios.post<boolean>(`${api}${MODULE}/modify/state/normal/batch`, payload);

/** 批量删除 */
export const deleteUserBatch = (payload: Ids) => axios.post<boolean>(`${api}${MODULE}/delete/batch`, payload);

/** 用户修改密码 */
export const userChangePassword = (payload: ChangePasswordType) => axios.post<boolean>(`${api}${MODULE}/change/password`, payload);

/** 查询未被授权给该企业的用户信息 */
export const findNonAuthUsersByCompanyId = (payload: FindAuthUsers) => axios.post<bucAPIResponse.UserList[]>(`${api}${MODULE}/findNonAuthUsersByCompanyId`, payload);

/** 设备端 系统管理-企业管理-查询未被授权给该企业的用户信息 */
export const findNonAuthUsersByInstallCompanyId = (payload: FindAuthUsers) => axios.post<bucAPIResponse.UserList[]>(`${api}${MODULE}/findNonAuthUsersByInstallCompanyId`, payload);

/**设备端 系统管理-企业管理-查询该企业授权过的用户信息 */
export const findUsersByCompanyId = (payload: FindAuthUsers) => axios.post<bucAPIResponse.UserList[]>(`${api}${MODULE}/findUsersByCompanyId`, payload);

/** 查询该企业授权过的用户信息 */
export const findAuthUsersByInstallCompanyId = (payload: FindAuthUsers) => axios.post<bucAPIResponse.UserList[]>(`${api}${MODULE}/findAuthUsersByInstallCompanyId`, payload);

/** 选择企业授权到用户 */
export const authOrgByUserId = (payload: any) => axios.post<boolean>(`${api}${MODULE}/org/auth/orgByuserId`, payload);

/** 解除授权 */
export const relieveAuth = (payload: any) => axios.post<boolean>(`${api}${MODULE}/org/auth/relieveById`, payload);

/** 用户信息-导出 */
export const exportUser = (payload: any) => axios.post<boolean>(`${api}${MODULE}/export`, payload);

/** 查询已授权给该用户的企业信息 */
export const findAuthCompanyByUserId = (payload: FindAuthUsers) => axios.post<bucAPIResponse.UserList[]>(`${api}/organization/findAuthCompanyByUserId`, payload);

/** 查询未被授权给该用户的企业信息 */
export const findNonAuthCompanyByUserId = (payload: FindAuthUsers) => axios.post<bucAPIResponse.UserList[]>(`${api}/organization/findNonAuthCompanyByUserId`, payload);

/** 用户管理 ==修改密码 */
export const userSetPassword = (payload: SetPasswordType) => axios.post<boolean>(`${api}${MODULE}/set/password`, payload);

/** 密码确认 */
export const verifyPassword = (payload: { verifyPassword: string }) => axios.post<boolean>(`${api}${MODULE}/verify/password`, payload);
