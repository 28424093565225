import React, { useEffect, useRef } from "react";
import { SelectCode, Table } from "../index";
import { FormInstance } from "antd/lib/form";
import useSetState from "util/useSetState";
import { DrawerProps } from "../drawer";
import Detail from "./commonDetail";
import { Button, Input } from "antd";
import axios from "server/axios";
import api from "server/web/index";
import { Access } from "components";

/**
 * 一个通用页面
 * 先通过某种方式，获取到页面配置，然后根据页面配置渲染出增删改查一套页面，具体方式待定
 * 模块名（用于区分这一整套，同时作为tableName）
 * 需要后台提供的内容：
 * 列表查询接口字符串，
 * 详情接口字符串，
 * 新增接口字符串，
 * 删除接口字符串，
 * 修改接口字符串，
 * column列表定义
 * filter字段定义
 * 新增和修改字段名和字段规则（后期优化一下让这俩能override）
 * @constructor
 */
const CommonPage = () => {
  const form = useRef<FormInstance>();

  const [state, setState] = useSetState<any>({
    tableName: undefined,
    deleteLoading: false,
    visible: false,
    drawerType: undefined,
    detailRecord: undefined,
    columns: undefined,
    formItems: undefined,
    queryUrl: undefined,
    detailUrl: undefined,
    infoItem: undefined,
  });

  useEffect(() => {
    setTimeout(() => {
      setState({
        columns: [
          {
            dataIndex: "deviceCode",
            intlTitle: "tx1805091",
            description: "设备编号",
            width: 160,
            fixed: "left",
          },
          {
            dataIndex: "companyName",
            title: "所属公司",
            width: 180,
          },
          {
            dataIndex: "productType",
            title: "设备型号",
            width: 200,
          },
          {
            dataIndex: "onlineStatus",
            title: "状态",
            width: 140,
          },
          {
            dataIndex: "plateNo",
            title: "车牌号",
            width: 140,
          },
          {
            dataIndex: "simNo",
            title: "终端手机号",
            width: 140,
          },
          {
            dataIndex: "iccid",
            title: "ICCID",
            width: 140,
          },
          {
            dataIndex: "calibrationStatus",
            title: "校准状态",
            width: 140,
          },
          {
            dataIndex: "newestDetectDate",
            title: "创建时间",
            width: 140,
          },
          {
            dataIndex: "remark",
            title: "备注",
            width: 140,
          },
          {
            dataIndex: "Action",
            intlTitle: "tx000007",
            description: "操作",
            width: 200,
            fixed: "right",
            render: (_text: any, record: any) => (
              <>
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    setState({
                      visible: true,
                      drawerType: "detail",
                      detailRecord: record,
                    });
                  }}
                >
                  详情
                </Button>
                <Access auth="edit">
                  <Button
                    type="link"
                    size="small"
                    onClick={() => {
                      setState({
                        visible: true,
                        drawerType: "update",
                        detailRecord: record,
                      });
                    }}
                  >
                    修改
                  </Button>
                </Access>
              </>
            ),
          },
        ],
        formItems: [
          {
            name: "simNo",
            dom: <Input placeholder={"请输入手机号"} />,
          },
          {
            name: "plateNo",
            dom: <Input placeholder={"请输入车牌号"} />,
          },
          {
            name: "onlineStatus",
            dom: (
              <SelectCode
                style={{ width: "1.5rem" }}
                placeholder={"请选择状态"}
                code="ipAction"
              />
            ),
          },
          {
            name: "productType",
            dom: (
              <SelectCode
                style={{ width: "1.5rem" }}
                placeholder={"请选择设备型号"}
                code="producttype"
              />
            ),
          },
          {
            name: "iccid",
            dom: <Input placeholder={"请输入ICCID"} />,
          },
          {
            name: "calibrationStatus",
            dom: (
              <SelectCode
                style={{ width: "1.5rem" }}
                placeholder={"请选择校准状态"}
                code="calibrationStatus"
              />
            ),
          },
        ],
        queryUrl: "/terminal/deviceInfo/page",
        detailUrl: "/terminal/deviceInfo/page",
        infoItem: [
          {
            children: [
              {
                dataIndex: "name",
                intlTitle: "tx130004",
                description: "企业名称",
              },
              {
                dataIndex: "parentName",
                intlTitle: "tx130005",
                description: "上级企业",
              },
              {
                dataIndex: "nameShort",
                intlTitle: "tx130102",
                description: "企业简称",
              },
              {
                dataIndex: "principal",
                intlTitle: "tx050003",
                description: "联系人",
              },
              {
                dataIndex: "principalPhone",
                intlTitle: "tx050002",
                description: "联系电话",
              },
              {
                dataIndex: "remark",
                intlTitle: "tx000105",
                description: "备注",
              },
            ],
          },
        ],
        tableName: "temp",
      });
      console.log("set complete");
    }, 2000);
  }, []);

  const {
    columns,
    formItems,
    tableName,
    visible,
    detailRecord,
    drawerType,
    queryUrl,
    detailUrl,
    infoItem,
  } = state;

  if (columns && formItems && tableName && queryUrl && infoItem) {
    const detailDrawer: DrawerProps = {
      visible: visible,
      title: "终端详情",
      width: 1200,
      children: (
        <Detail
          record={detailRecord}
          detailUrl={detailUrl!}
          infoItem={infoItem!}
        />
      ),
      onClose: () => {
        setState({ visible: false });
        detailRecord && setState({ detailRecord: undefined });
      },
    };

    return (
      <Table
        tableName={tableName!}
        columns={columns}
        rowKey="termId"
        axios={{ query: (param) => axios.post(api + queryUrl, param) }}
        form={form}
        searchItems={formItems}
        drawerOption={detailDrawer}
      />
    );
  } else {
    return <div>loading</div>;
  }
};

export default CommonPage;
