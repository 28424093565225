/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-07-27 19:01:09
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-25 18:15:37
 */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { message } from 'antd'
import handleCode from './code'
import { getLocal } from '../util/comm'
import { intlStore } from 'store'
import { isError } from 'lodash-es'

const defaultTimeout: number = 60000
const tokenFilter = ['token', 'mock']

/** 不需要拦截提示的code */
const filterCode = [-14, 0, 100, 1000, 83006, 10116, 10117]

/** 所有导出文件功能的urls标识集合（流文件） */
const exportUrls = [
  'export',
  'filenamager/getFileByPath',
  'filenamager/getZipByPathWithFtp',
  'opt/log/list',
]

/** 所有不需要错误提示的urls集合 */
const noMessageUrls = [
  '/webapi/sendCommand/getCommandStatus',
  '/webapi/sendCommand/findCommandStatusList',
]

// 把获取到的数据的null都过滤掉换成undefined
const replaceNull = (obj:any) => {
  for (let key in obj) {
    switch (Object.prototype.toString.call(obj[key]).slice(8, -1)) {
      case 'Object':
        replaceNull(obj[key])
        break;
      case 'Array':
        for (let i = 0; i < obj[key].length; i++){
          replaceNull(obj[key][i])
        }
        break;
      default:
        if (obj[key] === null) obj[key] = undefined;
    }
  }
}

const service: AxiosInstance = axios.create({
  timeout: defaultTimeout,
})

service.interceptors.request.use((config: AxiosRequestConfig) => {
  const { url } = config

  config.headers['lang'] = intlStore.lang
  config.headers['service-version'] = 'chenhs'

  if (!tokenFilter.some((item: string) => url?.includes(item))) {
    const token = getLocal('tx_token')
    // 没有token，跳转登录页面
    if (!token || token === 'undefined') {
      window.location.replace('/')
      return false as any
    }
    config.headers['Authorization'] = token
  }

  /** 处理所有导出url */
  if (exportUrls.some(item => url?.includes(item))) {
    config.responseType = 'blob'
  }

  return config
})

service.interceptors.response.use(
  (response: AxiosResponse<any>): any | Promise<any> => {
    const {
      data,
      data: { code },
      config: { url },
    } = response

    /** 拦截器统一过滤流文件 */
    if (data instanceof Blob) {
      /** 拦截json格式的流文件 */
      if (data.type === 'application/json') {
        ;(data as any).text().then((str: string) => {
          const json = JSON.parse(str)
          message.destroy()
          handleCode(json.code)
        })
        return false
      }
      return data
    }

    replaceNull(data)

    /** 拦截器统一过滤不需要错误提示的axios */
    let showMessage = true
    if (noMessageUrls.some(item => url?.includes(item))) {
      showMessage = false
    }

    if (response.status === 200) {
      // 处理高德地图api
      if (response.data.infocode === '10000') {
        return data
      }
      if (!filterCode.some(item => item === code)) {
        console.log('errorDate :>> ', response.data)
        message.destroy()
        handleCode(code, showMessage)
        return false as any
      }
      return data
    } else {
      console.log('response :>> ', response)
      message.destroy()
      return false as any
    }
  },
  err => {
    console.log('err :>> ', err)
    // 默认code
    let code = '1024'
    // 处理超时code
    if (isError(err) && err.message.includes(`timeout of ${defaultTimeout}ms`)) {
      code = '_1'
    }
    message.destroy()
    handleCode(code)
    return false as any
  },
)

export default service
