import {action, observable} from "mobx"
import {headerFaultListService} from "../server/web/device";

class FaultListStore {
  @observable count: number = 0

  @action
  updateCount = () => {
    headerFaultListService.getCount().then(res => {
      if (res) {
        this.count = res.data
      }
    })
  }
}

const faultListStore: FaultListStore = new FaultListStore()

export {FaultListStore}
export default faultListStore