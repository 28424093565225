/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-10-22 11:10:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-10-22 15:42:37
 */
import axios from "./axios";
import { AmapRequest, AmapResponse } from "./globals";
import { baseURL } from "./web/index";
/** 输入提示 */
export const inputTips = (params: AmapRequest.inputTips) =>
  axios.get<AmapResponse.inputTips[]>(`/amap/v3/assistant/inputtips`, {
    params,
  });

// 行政区域查询
export const district = (params: any) =>
  axios.get<any>(`/amap/v3/config/district`, {
    params,
  });

//路线查询
export const getLinePoint = (params: any) =>
  axios.post<any>(`${baseURL}/webapi/cruisePic/getLinePoint`, {
    params,
  });
