import React, { ReactNode } from 'react'
import classnames from 'classnames'
import './index.less'

export interface DescriptionsProps {
  /** 描述列表的标题，显示在最顶部 */
  title: ReactNode
  className?: string
  children?: any
}
export interface DescriptionsItemProps {
  /** 内容的描述 */
  label: string | JSX.Element
  children: any
  className?: string
  titleWidth?: number | string
  /** 所有title的文本位置 */
  direction?: 'right' | 'left' | 'center'
}

function Descriptions(props: DescriptionsProps) {
  return (
    <div className={classnames('tx-info-content', props.className)}>
      {props.title && <div className="tx-info-header">{props.title}</div>}
      {props.children}
    </div>
  )
}

function Item(props: DescriptionsItemProps) {
  const handleDirection = (direction?: 'right' | 'left' | 'center') => {
    let selfDirection = 'flex-end'
    if (direction === 'center') {
      selfDirection = 'center'
    } else if (direction === 'left') {
      selfDirection = 'flex-start'
    }
    return selfDirection
  }

  return (
    <div className="tx-info-item">
      {props.label ? (
        <div
          className={classnames('tx-info-item-label')}
          style={{
            width: props.titleWidth || 56,
            justifyContent: handleDirection(props.direction),
          }}
        >
          {props.label}
        </div>
      ) : null}
      <div className="tx-info-item-content">
        {props.children || props.children === 0 ? props.children : '-'}
      </div>
    </div>
  )
}

Descriptions.Item = Item

export default Descriptions
