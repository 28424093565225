/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-11-13 13:55:56
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-25 16:47:41
 */
import React, { memo } from "react";
import { Button } from "antd";
import { useIntl } from "react-intl";
import {
  PlusOutlined,
  FormOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { ExtraButtonGroupType } from "../interface";
import { Access } from "components";
import Import from "./import";
import Importtxt from "./importtxt";

interface ExtraButtonGroupProps {
  extraButtonValues: ExtraButtonGroupType;
  add: Function;
  copy: Function;
  threshold: Function;
  editBatch: Function;
  downloadTemplate: Function;
  imports?: Function;
  importtxt?: Function;
  templateDownload?: JSX.Element;
  tableName: string;
}

const ExtraButtonGroup = (props: ExtraButtonGroupProps) => {
  const {
    extraButtonValues: {
      showAdd,
      showCopy,
      showThreshold,
      showEditBatch,
      showImport,
      showImporttxt,
      showTemplateDownload,
      showDownloadTemplate,
      customize,
    },
    add,
    copy,
    threshold,
    editBatch,
    imports,
    importtxt,
    downloadTemplate,
    templateDownload,
    tableName,
  } = props;
  const { formatMessage: f } = useIntl();

  return (
    <div className="extraButtonGroup">
      {customize?.beforeAdd}
      {showAdd && (
        <Access auth="add">
          <Button onClick={() => add()} icon={<PlusOutlined />}>
            {f({ id: "tx000003", description: "新增" })}
          </Button>
        </Access>
      )}
      {customize?.afterAdd}
      {showCopy && (
        <Access auth="copy">
          <Button onClick={() => copy()}>{"复制"}</Button>
        </Access>
      )}
      {customize?.afterCopy}
      {showThreshold && (
        <Access auth="threshold">
          <Button onClick={() => threshold()}>{"阀值设置"}</Button>
        </Access>
      )}
      {customize?.afterThreshold}
      {showEditBatch && (
        <Access auth="batch_edit">
          <Button onClick={() => editBatch()} icon={<FormOutlined />}>
            {f({ id: "tx000021", description: "批量修改" })}
          </Button>
        </Access>
      )}
      {customize?.afterEditBatch}
      {showImport && (
        <Access auth="import">
          <Import imports={imports} tableName={tableName} />
        </Access>
      )}
      {customize?.afterImport}
      {showImporttxt && (
        <Access auth="import">
          <Importtxt importtxt={importtxt} tableName={tableName} />
        </Access>
      )}
      {customize?.afterImport}
      {showTemplateDownload && templateDownload}
      {customize?.afterTemplateDownload}
      {showDownloadTemplate && (
        <Access auth="import">
          <Button
            onClick={() => downloadTemplate()}
            icon={<DownloadOutlined />}
          >
            {f({ id: "tx000023", description: "模板下载" })}
          </Button>
        </Access>
      )}
      {customize?.afterDownloadTemplate}
    </div>
  );
};

export default memo(ExtraButtonGroup);
