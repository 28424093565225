import { PureComponent } from 'react'
import { CircleProps } from './interface';
import { pointConvert, handleOptions, getCircle, setView } from './util/comm'

const defaultOptions: Array<string> = [
  'center',
  'radius',
  'strokeColor',
  'strokeOpacity',
  'strokeWeight',
  'fillColor',
  'fillOpacity',
  'strokeStyle',
  'extData',
  'zIndex'
]

export default class Circle extends PureComponent<CircleProps> {

  map: any
  circle: any

  constructor(props: CircleProps) {
    super(props)
    this.map = props.__map__
    this.createCircle()
  }

  componentWillReceiveProps(_nextProps: CircleProps) {

  }

  componentWillUnmount() {
    this.remove()
  }

  async createCircle() {
    if (this.props.center) {
      const options = handleOptions(this.props, defaultOptions)

      // 批量转换
      const center: any = await pointConvert([this.props.center], this.props.type)

      options.center = center[0]
      // options.map = this.map
      this.circle = getCircle(this.props.type, options)
      if (this.props.type === 'amap') {
        this.circle.setMap(this.map)
      } else if (this.props.type === 'baidu') {
        this.map.addOverlay(this.circle)
      }
      if (this.props.isView) {
        setView(this.props.type, this.map, this.circle)
      }
    }
  }

  remove() {
    if (this.circle) {
      if (this.props.type === 'baidu') {
        this.map.removeOverlay(this.circle)
      } else if (this.props.type === 'amap') {
        this.map.remove(this.circle)
      }
      this.circle = null
    }
  }

  render() {
    return null
  }
}
