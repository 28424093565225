/**
 * TODO 这个组件目前不太规范，需要整理一下
 * TODO 这个组件的大屏地图部分功能有内存泄漏，需要处理
 */

import React, { useEffect, useRef, useReducer, CSSProperties } from 'react'
import * as echarts from "echarts";
import "echarts-gl";
import { EChartOption } from 'echarts/lib/echarts'
import { BampProps } from './interface'
import { MapDarkStyle } from './theme'
import china from "map/json/china.json"
// 引入地图
import 'echarts/lib/chart/map'
import { toLngLat } from 'components/map/util/comm'

export interface EchartsProps extends EChartOption {
  className?: string
  aibpoints?: Array<[number, number]>
  style?: CSSProperties
  bmap?: BampProps
}

require("echarts/dist/extension/bmap")

const Reducer = (state: any, action: any) => {
  const { type, data } = action

  switch (type) {
    case 'create':
      state = data
      return state
    case 'update':
      if (state) {
        state.resize()
      }
      return state
    case 'dispose':
      if (state) {
        state.dispose()
      }
      state = null
      return state
    default:
      return state
  }
}

const Index = (props: EchartsProps) => {
  const { className, ...extra } = props
  const ehcartsElement: any = useRef()
  const [myChart, dispatch] = useReducer(Reducer, null)
  const _bmap: any = useRef()

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      dispose()
      window.removeEventListener('resize', resize)
    }
  }, [])

  useEffect(() => {
    echarts.registerMap("china", { geoJSON: china });
    const selfChart: any = echarts.init(ehcartsElement.current)
    if ('bmap' in props) {
      selfChart.setOption({ ...extra }, true)
      var bmap = selfChart.getModel().getComponent('bmap').getBMap()
      _bmap.current = bmap
      bmap.setMapStyleV2({ styleJson: MapDarkStyle })
      dispatch({ type: 'create', data: selfChart })
    } else {
      selfChart.setOption({ ...extra })
      dispatch({ type: 'create', data: selfChart })
    }
  }, [ehcartsElement.current])

  useEffect(() => {
    if (myChart) {
      myChart.setOption({ ...extra })
      if (extra.bmap && extra.bmap.isViewport && _bmap.current) {
        if (extra.bmap?.viewport && extra.bmap.viewport.length) {
          _bmap.current.setViewport(extra.bmap.viewport?.map(item => toLngLat(item, 'baidu')))
        }
      }
      myChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: 0
      })
    }
  }, [props.series])

  useEffect(() => {
    if (myChart && _bmap.current) {
      const { aibpoints = [] } = props
      // const aibpoints = [
      //   [104.114129, 35.850339],
      //   [116.404, 39.915],
      //   [116.328896, 34.988039],
      //   [119.328896, 35.988039],
      //   [108.328896, 33.988039],
      //   [116.328896, 32.988039],
      //   [102.328896, 30.988039],
      //   [115.348896, 37.988039],
      //   [116.378896, 38.988039],
      // ];
      const options = {
        // @ts-ignore
        size: BMAP_POINT_SIZE_SMALL,
        // @ts-ignore
        shape: BMAP_POINT_SHAPE_CIRCLE,
        color: '#287d02'
      }
      let allOverlay = _bmap.current.getOverlays();
      for (let i = 0; i < allOverlay.length; i++) {
        // 有root那个overlay如果被remove，会报错
        if (!allOverlay[i].hasOwnProperty('_root')) {
          _bmap.current.removeOverlay(allOverlay[i]);
        }
      }
      if (aibpoints.length) {
        const temp = aibpoints.map((i) => {
          // @ts-ignore
          let point = new BMap.Point(i[0], i[1])
          return point
        })
        // @ts-ignore
        let pointCollection = new BMap.PointCollection(temp, options);
        _bmap.current.addOverlay(pointCollection)
      }

      // let markers: any = [];
      // let markerClusterer = new BMapLib.MarkerClusterer(_bmap.current, { markers: markers });
      // let pt = null;
      // if (aibpoints.length) {
      //   for (let i = 0; i < aibpoints.length; i++) {
      //     // for (let i = 0; i < 50000; i++) {
      //       // @ts-ignore
      //       pt = new BMap.Point(aibpoints[i][0], aibpoints[i][1]);
      //       // pt = new BMap.Point(Math.random() * 40 + 85, Math.random() * 30 + 21);
      //       // @ts-ignore
      //       markers.push(new BMap.Marker(pt));
      //     };
      //     // let markerClusterer = new BMapLib.MarkerClusterer(_bmap.current, { markers: markers });
      //     // var markerClusterer = new BMapLib.MarkerClusterer(_bmap.current);
      //     markerClusterer.addMarkers(markers);

      //   }
      }
    }, [props.aibpoints])

  useEffect(() => {
    if (myChart && _bmap.current && props.bmap?.center) {
      // @ts-ignore
      _bmap.current.setCenter(new BMap.Point(props.bmap?.center[0], props.bmap?.center[1]))
      _bmap.current.setZoom(props.bmap.zoom)
    }
  }, [props.bmap?.center, props.bmap?.zoom])

  const resize = () => {
    dispatch({ type: 'update' })
  }

  const dispose = () => {
    dispatch({ type: 'dispose' })
  }

  return (
    <div
      ref={ehcartsElement}
      className={className}
      style={{ width: '100%', height: '100%', ...props.style }}
    >
    </div>
  )
}

export default Index
